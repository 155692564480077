<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title>
      <a-btn-unblock
        v-if="getAccess('documents.orderGoods.status', { users: [data.createdby_id] })"
        :id="data.id"
        @unblock="fitchData()"
        :api="api"
        :blocked="data.blocked"
      />
      ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] <a-btn-print @click="showPrint = true" />
      <a-btn-docHistory :m="m" :id="data.id || null" @clickDoc="gotoDoc($event)" />
    </template>
    <template v-slot:actions v-if="loaded">
      <v-tooltip bottom v-if="hintStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="ma-1">fas fa-info-circle</v-icon>
        </template>
        <span v-html="hintStatus"></span>
      </v-tooltip>
      <a-btn-add v-if="correctMode" icon="far fa-check-square" title="Сохранить" @click="correctSave()" />
      <a-btn-add v-if="correctMode" icon="far fa-window-close" title="Отменить" @click="correctCancel()" />
      <a-btn-add
        v-if="!correctMode && !showOnly && data.status == 2 && getAccess('documents.orderGoods.correct')"
        icon="far fa-edit"
        :title="'Правка'"
        @click="correctStart()"
      />
      <a-btn-add
        v-if="!correctMode && !showOnly && data.status == 2 && getAccess('documents.goodsIncome.create')"
        :icon="'fas fa-truck'"
        :title="'Принять'"
        @click="showListIncome = true"
      />

      <a-btn-status
        v-if="
          data.status != 6 &&
          !correctMode &&
          !showOnly &&
          getAccess('documents.orderGoods.status', {
            users: [data.createdby_id],
          })
        "
        :hintStatus="hintStatus"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="!canStatus"
      />
      <a-btn-edit v-if="!showOnly && data.status === 1 && accessEdit" @click="showEditDialog = true" :disabled="canEdit" />
      <a-btn-delete
        v-if="
          data.status === 1 &&
          !showOnly &&
          id &&
          data.data_table &&
          !data.data_table.length &&
          getAccess('documents.orderGoods.delete', {
            users: [data.createdby_id],
          })
        "
        @click="removeDialogShow = true"
      />
    </template>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row style="height: 100%">
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true }" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="balance"
            :model="[
              { name: 'credit', title: 'Итого к оплате', type: 'number' },
              { name: 'debit', title: 'Оплачено', type: 'number' },
              {
                name: 'saldo',
                value: balance.credit - balance.debit,
                title: 'Долг',
                type: 'number',
              },
            ]"
            :config="{ dense: true }"
            :style1="{ height: blockHeight + 'px' }"
            @click="getBalance()"
          />
          <a-btn-add v-if="data.status === 1 && accessEdit" @click="setDiscount()" :title="'Скидка'" :disabled="balance.credit == 0" :icon="'fas fa-percent'" />
          <a-btn-add
            v-if="false && data.status === 2 && !data.payorder_id"
            @click="createPayOrder()"
            :title="'Подать заявку на оплату'"
            :disabled="balance.debit !== 0 || balance.credit == 0 || !getAccess('payOrder.create')"
            :icon="'fas fa-search-dollar'"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2" style="flex: 1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(t, i) in tabs" v-if="t.show" :href="`#tab-${t.name}`" :key="i" class="mr-3"> {{ t.title }} </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'detail'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableDetail"
                  :dataTable="data.data_table"
                  :useQuery="false"
                  :model="
                    modelDetail.filter(m => {
                      return m.statusHide !== data.status || (m.statusHide == 2 && correctMode);
                    })
                  "
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentOrderGoodsDetail',
                  }"
                  @click="onClickRow($event)"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="(data.status === 1 || (row && correctMode && !row.amount_income)) && accessEdit">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delRow(row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition v-if="data.status == 1 && accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addRecord">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                    <v-fab-transition v-if="data.status == 1 && $root.profile.role == 1000">
                      <v-btn fab dark color="purpul" absolute bottom small style="bottom: 15px; left: 60px" left class="v-btn--example" @click="dialogImport = true">
                        <v-icon>mdi-import</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
              <v-card-text v-if="t.name == 'service'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-data2
                  ref="tableServiceDetail"
                  :dataTable="data.data_service"
                  :useQuery="false"
                  :model="
                    modelServiceDetail.filter(m => {
                      return m.statusHide !== data.status;
                    })
                  "
                  :searchable="false"
                  :defaults="{
                    filters: {},
                    sort: { key: 'id', order: 'DESC' },
                    paramName: 'documentOrderGoodsServiceDetail',
                  }"
                  @click="onClickRow($event, 'service')"
                >
                  <template v-slot:item.actions="{ row }">
                    <div class="d-flex" v-if="data.status === 1 && accessEdit">
                      <v-btn x-small fab class="ma-0" color="red" title="Удалить" @click.stop="delServiceRow(row.id)">
                        <v-icon small>fas fa-times</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <template v-slot:top>
                    <v-fab-transition v-if="data.status == 1 && accessEdit">
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="addServiceRecord">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-data2>
              </v-card-text>
              <s-tabs-cash
                v-if="t.name == 'cash'"
                :data="data"
                :operations="m.operationsCash[data.type] || []"
                type="full"
                :canAdd="data.status == 2 && accessEdit && checkPayOrder()"
                :m="m"
                :height="detailHeight"
              />
              <s-tabs-pay-order
                ref="tabPayOrder"
                v-if="t.name == 'payOrder'"
                :parent="m.accDocName"
                :balance="balance"
                :data="data"
                :canAdd="getAccess('payOrder.create')"
                :m="m"
                @add="createPayOrder($event)"
                :height="detailHeight"
              />
              <comment-view v-if="t.name == 'comments'" :height="detailHeight" :id="id || 0" :targetName="'orderGoods'" :needUpdate="needUpdateComments" />

              <v-card-text v-if="t.name == 'account'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record
                  :id="id"
                  :name="accDocName"
                  :join="{
                    AccountingDocOrderReceiptModel: { parent_name: m.accDocName, parent_id: id },
                    AccountingDocOrderWithdrawalModel: { parent_name: m.accDocName, parent_id: id },
                    AccountingDocOrderGoodsModel: { credit_subconto_value_2: id, storno: 1 },
                  }"
                  :orWhere="[{ base_name: 'AccountingDocOrderGoodModel', credit_subconto_value_2: id, storno: 1 }]"
                />
              </v-card-text>

              <v-card-text v-if="t.name == 'allFiles'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-view-files2 v-if="tab == 'tab-allFiles'" :id="data.id" :name="m.accDocName" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading && false" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <s-edit-form
        ref="payOrder"
        :opt="{ newWindow: true, fieldsEditable: 'amount', closeAfterSave: true }"
        v-if="showPayOrderDialog"
        v-model="showPayOrderDialog"
        :configForm="{ type: 'create' }"
        :m="payOrderModel"
        :initData="payOrderData"
      />
      <edit-dialog
        v-if="showEditDialog"
        v-model="showEditDialog"
        :id="id"
        :api="url"
        :m="m"
        :readonly="data.status == 2"
        :initType="data.type"
        :hasData="data.data_table && data.data_table.length > 0"
      />
      <add-Goods v-if="showDialogAddGoods" v-model="showDialogAddGoods" :object_id="parseInt(data.object_code)" @save="addRows($event)" />
      <edit-Goods
        v-if="showDialogRowEdit"
        v-model="showDialogRowEdit"
        :id="idEdit"
        :type="data.operation_type"
        :dataTable="data.data_table"
        :readonly="data.status == 2 && !correctMode"
        :RO="fieldsRO"
        @save="editRows($event)"
        @refresh="fitchData()"
      />
      <edit-Goods-Service
        v-if="showDialogServiceRowEdit"
        v-model="showDialogServiceRowEdit"
        :id="idEdit"
        :type="data.operation_type"
        :dataTable="data.data_service"
        :readonly="data.status == 2"
        :RO="fieldsRO"
        @save="editServiceRows($event)"
        @refresh="fitchData()"
      />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <CreateIncomeDialog
        v-model="showCreateIncome"
        v-if="showCreateIncome"
        :api="'/accounting/doc/goods_income'"
        :m="$store.getters['config/get'].models.documentGoodsIncome"
        :id="0"
        :details="detailsIncome"
      />
      <ListGoodsIncome v-if="showListIncome" v-model="showListIncome" :object_id="null" :order_id="id" :type="'addFromList'" @save="createDocIncome($event)" />
      <a-btn-import v-model="dialogImport" :parent_id="id" api="accounting/doc/order_good/import_table" />
      <PrintForm v-if="showPrint" v-model="showPrint" :data="data" :models="{ modelLeft, modelDetail }" :fields="m.printField" />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <s-Editor-editor v-if="getAccess('suData')" ref="suEdit" v-model="suEditShow" :config="configSU" @show="suEditShow = true" :activate="(data.id || 0) > 0" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel, comments } from "@/components/mixings";
import libsCash from "./../libsCash";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, saveEl, genModel, libsCash, comments],
  components: {
    addGoods: () => import("./../dialogs/orderAddGoodsDialog"),
    editGoods: () => import("./../dialogs/orderEditGoodsDialog"),
    editGoodsService: () => import("./../dialogs/orderEditGoodsServiceDialog"),
    editDialog: () => import("./../dialogs/orderCreateDialog"),
    ListGoodsIncome: () => import("./../dialogs/incomeAddGoodsDialog"),
    CreateIncomeDialog: () => import("./../dialogs/goodsIncomeCreateDialog"),
    PrintForm: () => import("@/views/print/goodsIncome"),
  },
  props: {
    idShow: Number,
    showOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      idEditCash: 0,
      idDeleting: 0,
      fieldsRO: [],
      detailsIncome: null,
      correctMode: false,
      accDocName: "AccountingDocOrderGoodModel",
      suEditShow: false,
      suEditConfig: null,
      showPrint: false,
      dialogImport: false,
      showPayOrderDialog: false,
      showListIncome: false,
      showCreateIncome: false,
      showCashSelectDialog: false,
      showCashCreateDialog: false,
      showEditDialog: false,
      showDialogRowEdit: false,
      showDialogServiceRowEdit: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      loading: false,
      loaded: false,
      title: "",
      data: {},
      model1: this.$store.getters["config/get"].models.documentOrderGoods.form,
      modelDetail: this.$store.getters["config/get"].models.documentOrderGoods.listDetail,
      modelServiceDetail: this.$store.getters["config/get"].models.documentOrderGoods.listServiceDetail,
      m: this.$store.getters["config/get"].models.documentOrderGoods,
      statuses: this.$store.getters["config/get"].models.documentOrderGoods.statuses,
      url: "/accounting/doc/order_good",
      api: "/accounting/doc/order_good",
      apiStatus: "/accounting/doc/order_good",
      tab: 0,

      showDialogAddGoods: false,
      blockHeight: 256,
      debit: null,
      credit: null,
      statusCur: null,
      hintStatus: null,
      payOrderData: null,
      payOrderModel: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = "Заказ товаров";
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    // this.loaded = true;
  },
  mounted() {},

  computed: {
    configSU() {
      let res = {};
      if (this.showDialogRowEdit) res = { table: "accounting_doc_order_goods_table", filter: { parent_id: this.data.id } };
      else res = { table: "accounting_doc_order_goods", filter: { id: this.data.id } };
      return res;
    },
    tabs() {
      let res = [
        { name: "detail", title: "Детали", show: true },
        { name: "service", title: "Доп. расходы", show: true },
        { name: "cash", title: "Оплата", show: !this.correctMode },

        { name: "payOrder", title: "Заявки", show: !this.correctMode },
        { name: "allFiles", title: "Документы", show: 1 },
        { name: "comments", title: "Комментарии", show: !this.correctMode && this.getAccess(`documents.orderGoods.comments`, null, true) },
        { name: "account", title: "Проводки", show: !this.correctMode && this.getAccess("documents.orderGoods.accounting") },
      ];
      return res;
    },
    modelLeft() {
      let type = this?.data?.type || 0;
      let hide = "";
      if (type == 1) hide = "store_id,office_id";
      if (type == 2) hide = "store_id,object_code";
      if (type == 3) hide = "office_id,object_code";
      let model = this.calcModel("viewForm1");
      model.forEach(el => {
        if (hide.split(",").includes(el.name)) el.type = null;
      });
      return model;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      this.hintStatus = "";
      if (!this.data.vendor_id) {
        this.hintStatus += "Не заполнен поставщик<br/>";
        return false;
      }
      if (this.data?.doc_type == 2 && false) {
        this.hintStatus += "Корректирующий документ нельзя распроводить<br/>";
        return false;
      }
      this.hintStatus += this.data.blocked ? "Документ заблокирован <br/>" : "";

      if (
        this.data.status == 1 &&
        this.data.data_table &&
        Array.isArray(this.data.data_table) &&
        this.data.data_table.filter(el => {
          return !el.category_id || !el.date_before;
        }).length
      ) {
        this.hintStatus += "В товарах не заполнены данные (категория/доставка)<br/>";
      }
      return !(this.data.data_table && this.data.data_table.length == 0) && !this.loading && !this.hintStatus;
    },
    accessEdit() {
      return this.getAccess("documents.orderGoods.edit", { users: [this.data.createdby_id] });
    },
    canEdit() {
      if (!this.data.vendor_id) {
        return false;
      }

      return this.loading || this.data.data_table.length !== 0 || false;
    },
    model: {
      get() {
        let model = this.getModelList(this.m, "form", true);
        this.modelReady = true;
        return model;
      },
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter(s => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find(el => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showPayOrderDialog(v) {
      if (!v) {
        this.payOrderData = null;
        this.payOrderModel = null;
        this.$refs.tabPayOrder[0].refresh();
      }
    },
    dialogImport(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },

    showCashCreateDialog__() {
      if (!this.showCashCreateDialog) {
        this.$refs.tableCashOut[0].updateData();
      }
    },
    "$route.params.id"(newId, oldId) {
      this.id = newId;
      this.loading = true;
      //    this.permit = this.getAccess("menu.objects");
      this.fitchData();
    },
  },
  methods: {
    checkPayOrder() {
      let res = true;
      if (this.data.pay_orders_data) {
        const d = this.data.pay_orders_data;

        if (d.length && d.find(p => p.payment_type == 1 && [0, 1].includes(p.status))) res = false;
      }
      return res;
    },
    openPayOrder() {
      if (!this.data.payorder_id) return;
      const routeData = this.$router.resolve({ name: "payOrder_view", params: { id: this.data.payorder_id } });
      window.open(routeData.href, "_blank");
    },
    createPayOrder(v) {
      let data = {
        parent_name: this.m.modelName,
        parent_id: this.data.id,
        amount: v !== undefined ? v : this.balance.credit,
        type: "orderGoodsPayment",
        object_id: this.data.type == 1 ? this.data.object_code : null,
        name: `Требуется оплата заказа товаров №${this.data.code_doc}`,
      };
      this.payOrderData = data;
      let model = Object.assign({}, this.$store.getters["config/get"].models.payOrder);
      (model?.form || []).find(m => m.name == "amount").validator = ["min#0", `max#${data.amount}`];
      this.payOrderModel = model;

      //console.log("payOrderModel", this.payOrderModel);
      this.showPayOrderDialog = true;
      this.$nextTick(() => {
        console.log(this.$refs.payOrder);
        //        this.$refs.payOrder.inputData(data);
      });
    },
    reload() {},
    gotoDoc(id) {
      if (id == this.id) return;
      if (this.idShow) {
        this.id = id;
        this.fitchData();
      } else this.$router.push({ name: "ordersGoods_view", params: { id } });
    },

    correctStart() {
      if (this.balance?.debit) {
      } else {
        this.$root.$emit("show-info", { text: "Документ нельзя корректировать. Можно распровести " });
        return;
      }
      delete this.data.blocked;
      this.correctMode = true;
    },
    correctCancel() {
      this.correctMode = false;
      this.fitchData();
    },
    async correctSave() {
      this.correctMode = false;
      const api = this.url;
      const data = Object.assign({}, this.data);
      data["parent_id"] = data.id;
      data["parent_name"] = "AccountingDocOrderGoodModel";
      data["origin_id"] = data?.origin_id || data.id;
      data["doc_type"] = 2;
      delete data.id;
      data.data_table.forEach(d => {
        delete d.id;
      });
      data.data_service.forEach(d => {
        delete d.id;
      });
      //return;
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка изменения данных",
        });
      }
      this.id = response.data.data.id;
      this.$router.push({ name: this.m.routeName, params: { id: this.id } });

      //await this.fitchData();
    },
    onClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "object_code") {
          let name = d.row.type == 1 ? "object" : d.row.type == 2 ? "office" : "storage";

          this.showPopupMenu(name, { id: d.row.object_code }, d.event);
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    async fitchData(id = 0) {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.hintStatus = null;
      this.loading = false;
    },
    async getBalance() {
      console.log("getBalance");
      let debit = 0;
      let credit = 0;
      if (this.data.status === 1) {
        this.data.data_table.forEach(el => {
          credit += el.price_real;
        });
      }
      //const acc = this.config.debit;
      let resp = await this.$axios.post("accounting/records/report-sql", {
        q: `
select sum(value_cr) 'value_cr', sum(value_db) 'value_db' from (
select id, credit_account 'account', value 'value_cr', null 'value_db'
from accounting_record
where credit_account like "60.1" and credit_subconto_value_2=${this.id}
union all
select id, debit_account 'account',	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "60.1" and debit_subconto_value_2=${this.id}
)  tt`,
      });

      if (resp.data.status == "ok") {
        resp.data.data.forEach(el => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }
      this.credit = credit;
      this.debit = debit;
    },
    onClickRow(e, type = "") {
      if (e.field?.isMenu) {
        let name = e.field?.alias || e.field.name;
        let id = e.row?.[e.field.name];
        if (id) this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        this.idEdit = e.row.id;
        if (type == "service") {
          this.showDialogServiceRowEdit = true;
          return;
        }
        this.fieldsRO =
          this.data.operation_type == 1 //по чек-листу
            ? ["name", "amount", "measure", "price_plan"]
            : [];
        this.showDialogRowEdit = true;
      }
    },
    addRecord() {
      if (this.data.operation_type == 1) {
        this.showDialogAddGoods = true;
        return;
      }
      if ([2, 10, 20].includes(this.data.operation_type)) {
        this.idEdit = 0;
        this.fieldsRO = [];
        this.showDialogRowEdit = true;
        return;
      }
    },
    async addRows(r) {
      if ([1, 2, 3].includes(this.data.type)) {
        const api = this.url;
        const data_table = [...this.data.data_table, ...r];
        const data = { id: this.id, data_table };
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка добавления данных",
          });
        }
        this.fitchData();
      }
    },
    async editRows(r) {
      if (this.correctMode) {
        this.data.data_table = [...r];
        if (this.data.data_service.length) await this.calcTotalPrice(this.data.data_service);
        this.getBalance();
        return;
      }
      if ([1, 2, 3].includes(this.data.type)) {
        const api = this.url;
        const data_table = r;
        const data = { id: this.id, data_table };
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка изменения данных",
          });
        }
        await this.fitchData();
        if (this.data.data_service.length) this.editServiceRows();
      }
    },
    async delRow(id) {
      if (this.correctMode) return;
      if (this.idDeleting) return;
      if ([1, 2, 3].includes(this.data.type)) {
        this.idDeleting = id;
        const api = this.url;
        const data_table = this.data.data_table.filter(el => el.id !== id);
        const data = { id: this.id, data_table };
        try {
          let response = await this.$axios.post(api, data);
          let status = response.data.status == "ok";
          if (status) {
            this.$root.$emit("show-info", {
              text: "Данные удалены",
            });
          } else {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Ошибка удаления ",
            });
          }
          await this.fitchData();
        } catch (error) {}
        this.idDeleting = 0;

        if (this.data.data_service.length) this.editServiceRows();
      }
    },
    calcTotalPrice(data_service) {
      const data_table = this.data.data_table;
      let price_adding = 0;
      let price_total = 0;
      //считаю сумму которую надо добавить или отнять
      data_service.forEach(el => {
        price_adding += parseFloat(el.value);
      });
      //считаю стоимость чистую общюю
      data_table.forEach(el => {
        price_total += el.price_real - (el.price_adding ? el.price_adding : 0);
      });
      let applay = 0;
      data_table.forEach((el, idx, array) => {
        //расчтываю долю на позицию пропорционально его чистой стоимости
        let real = parseFloat(el.price_real - (el.price_adding ? el.price_adding : 0));
        let d = parseFloat(((price_adding * real) / price_total).toFixed(2));
        if (idx === array.length - 1) {
          //если последняя позиция, то весь остаток на нее
          el.price_real = real + price_adding - applay;
          el.price_adding = price_adding - applay;
        } else {
          el.price_real = real + d;
          applay += d;
          el.price_adding = d;
        }
      });
      return data_table;
    },
    async editServiceRows(r) {
      if (this.correctMode) {
        return;
      }
      if (!r) r = this.data.data_service;
      console.log("Изменение накрутки");
      if ([1, 2, 3].includes(this.data.type)) {
        const api = this.url;
        const data_service = r;
        const data_table = await this.calcTotalPrice(r);
        const data = { id: this.id, data_service, data_table };
        let response = await this.$axios.post(api, data);
        let status = response.data.status == "ok";
        if (status) {
          this.$root.$emit("show-info", {
            text: "Данные сохранены",
          });
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка изменения данных",
          });
        }
        this.fitchData();
      }
    },
    addServiceRecord() {
      this.idEdit = 0;
      this.fieldsRO = [];
      this.showDialogServiceRowEdit = true;
    },
    async delServiceRow(id) {
      if (this.correctMode) return;
      if (this.idDeleting) return;
      if ([1, 2, 3].includes(this.data.type)) {
        this.idDeleting = id;
        const api = this.url;
        const data_service = this.data.data_service.filter(el => el.id !== id);
        const data_table = await this.calcTotalPrice(data_service);
        const data = { id: this.id, data_service, data_table };
        //const data = { id: this.id, data_service };
        try {
          let response = await this.$axios.post(api, data);
          let status = response.data.status == "ok";
          if (status) {
            this.$root.$emit("show-info", {
              text: "Данные удалены",
            });
          } else {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Ошибка удаления ",
            });
          }
        } catch (error) {}
        this.idDeleting = 0;
        this.fitchData();
      }
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", data);
      else this.$router.push({ name: "ordersGoods" });
    },
    afterFetchData(r) {
      if (this.id) {
        this.getBalance();
      }
      console.log("afterFetchData");
      this.loaded = true;
    },
    async setDiscount() {
      let ok = false;
      ok = await this.$refs.changeValueDialog.show({
        title: "Изменение общей стоимости",
        message: [`Укажите итоговую стоимость за весь заказ с учетом скидки`],
        okButton: "Подтвердить",
        cancelButton: "Отмена",
        model: [
          {
            name: "price",
            title: "итоговая стоимость",
            type: "number",
            max: this.balance.credit,
            validator: ["req", "max"],
          },
        ],
      });
      if (!ok || !ok?.status) return;
      const data_service = this.data.data_service;
      const data_table = this.data.data_table;
      const price_total = ok.data.price;
      let discount = this.balance.credit - price_total;
      if (data_service.findIndex(el => el.service_id == 33) == -1) {
        data_service.push({ service_id: 33, value: -discount });
      } else {
        data_service.find(el => el.service_id == 33).value -= discount;
      }
      this.editServiceRows(data_service);
    },
    async changeStatus(status) {
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} заказ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.value !== 2) {
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select id from accounting_doc_order_withdrawal where parent_id=${this.id} and parent_name="${this.accDocName}" and deleted<>1`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ оплаты",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
        resp = await this.$axios.post("accounting/records/report-sql", {
          q: `
select ogt.id from accounting_doc_order_goods_table ogt
left join accounting_doc_goods_income_table git on git.accounting_doc_order_goods_table_id = ogt.id
where ogt.parent_id=${this.id} and git.id is not null`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ приемки",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
      }
      let data = { id: this.id, status: status.value };
      let response = await this.save(this.apiStatus, data);
      if (response && response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Статус не изменен",
        });
      }
      return;
    },

    createDocIncome(details) {
      console.log("create doc income");
      this.detailsIncome = details;
      this.showCreateIncome = true;
    },
  },
};
</script>
